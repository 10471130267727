<template>
  <view-item title="采购对账单详情">
    <template #operation>
      <button-ajax v-if="form.data.id && form.data.status?.code === 'P'" :method="operation.submit.click"> 保存 </button-ajax>
      <button-ajax
        v-if="form.data.id && form.data.status?.code === 'P' && form.data.payables?.every((item) => item?.payableVerified?.code === 'Y')"
        :method="operation.audit.click"
      >
        提交对账单
      </button-ajax>
      <button-ajax v-if="form.data.id && form.data.status?.code !== 'P'" :method="operation.return.click" type="info"> 退回 </button-ajax>
      <button-ajax v-if="form.data.id && form.data.status?.code === 'A'" :method="operation.payment.click"> 付款 </button-ajax>
      <button-ajax v-if="form.data.id && form.data.status?.code === 'S'" :method="operation.cancel.click"> 取消付款 </button-ajax>
      <en-dropdown @command="operation.add.command">
        <en-button type="primary">操作</en-button>
        <template #dropdown>
          <en-dropdown-item v-if="form.data.id && form.data.status?.code === 'P'" command="other">添加单据</en-dropdown-item>
          <en-dropdown-item
            v-if="form.data.id && form.data.status?.code === 'P'"
            command="del"
            :disabled="!form.data.payables?.every((item) => item?.payableVerified?.code === 'N')"
            >删除</en-dropdown-item
          >
          <en-dropdown-item v-if="form.data.id && form.data.status?.code === 'P'" command="statement">一键对账</en-dropdown-item>
          <en-dropdown-item v-if="form.data.id" command="logs">单据历史</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <template #manifest>
      <table-manifest
        :ajax="{
          action: 'GET /enocloud/settlement/payable/statement/query'
        }"
        code="PYBESEFD"
        :props="{ start: 'reservationStartDate', end: 'reservationEndDate' }"
        @expand-click="payDialog.visible = true"
        @row-click="manifest.row.click"
      >
        <template #form="{ data }">
          <en-form-item label="对账单号">
            <en-input v-model="data.serialNo"></en-input>
          </en-form-item>
          <en-form-item label="供应商">
            <en-input v-model="data.payeeName"></en-input>
          </en-form-item>
          <en-form-item label="单据类别">
            <select-maintain
              v-model="data.type"
              :ajax="{
                action: 'GET /enocloud/common/lookup/:lookupType',
                params: (params) => {
                  params.paths = ['PABTYPE']
                  params.payload = { excludes: ['POLRBT'] }
                }
              }"
              :props="{ label: 'message', value: 'code' }"
              value-key="code"
              class="w-full"
            ></select-maintain>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading" body-class="flex flex-col gap-6">
        <en-collapse>
          <en-collapse-item>
            <template #title>
              <div class="flex items-center gap-6">
                <span>{{ form.data?.type?.message }}</span>
                <span>{{ form.data?.serialNo }}</span>
                <span>{{ formatMoney(form.data?.payableAmount) }}(对账金额)</span>
                <span>{{ form.data?.preparedBy?.name }}</span>
                <span>{{ formatDate(form.data?.auditDatetime, true) }}</span>
                <span>{{ form.data?.comment }}</span>
              </div>
            </template>
          </en-collapse-item>
        </en-collapse>
      </en-card>

      <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-table :data="form.data.payables" :height="height" :loading="form.loading">
              <en-table-column label="操作" v-if="form.data?.status?.code === 'P'">
                <template #default="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
                  <button-ajax v-if="row.payableVerified?.value" link :method="form.payables.cancel.click" :params="row">取消对账</button-ajax>
                  <button-ajax v-else link :method="form.payables.statement.click" :params="row">对账</button-ajax>
                  <en-button v-if="row.payableVerified?.code === 'N'" @click="form.payables.delete.click" :params="row" text>删除</en-button>
                </template>
              </en-table-column>
              <en-table-column label="单号">
                <template #default="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
                  <en-button text @click="operation.open.click(row)" v-if="row.type.code === 'OTH' || row.type.code === 'PUC'">{{
                    row.documentSerialNo
                  }}</en-button>
                  <span v-else="row.type.code !== 'OTH' || row.type.code !== 'OTH'">{{ row.documentSerialNo }}</span>
                </template>
              </en-table-column>

              <en-table-column label="发票类型" prop="invoiceTypeName" v-if="form.data.type?.code === 'PUC'"></en-table-column>
              <en-table-column label="采购数量" prop="purchase.totalCount" v-if="form.data.type?.code === 'PUC'"> </en-table-column>
              <en-table-column label="已入库数" prop="purchase.stocked.count" v-if="form.data.type?.code === 'PUC'"></en-table-column>
              <en-table-column label="采购金额" v-if="form.data.type?.code === 'PUC'">
                <template #default="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
                  {{ formatMoney(row.purchase?.totalAmount) }}
                </template>
              </en-table-column>
              <en-table-column label="物流费" v-if="form.data.type?.code === 'PUC'">
                <template #default="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
                  {{ formatMoney(row.purchase?.freight) }}
                </template>
              </en-table-column>
              <en-table-column label="物流类型" prop="purchase.freightPaymentMethod.message" v-if="form.data.type?.code === 'PUC'"></en-table-column>
              <en-table-column label="应付总额">
                <template #default="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
                  {{ formatMoney(row.amount) }}
                </template>
              </en-table-column>
              <en-table-column label="制单人" prop="preparedBy.name"></en-table-column>
              <en-table-column label="单据日期">
                <template #default="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
                  {{ formatDate(row.preparedDatetime) }}
                </template>
              </en-table-column>
              <en-table-column label="备注" prop="comment"></en-table-column>
            </en-table>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>

  <pay-dialog v-model="payDialog.visible"></pay-dialog>

  <statementOther-detail v-model="statementOtherDetail.visible"></statementOther-detail>

  <batch-details v-model="batchDetail.visible" :data="form.data"></batch-details>

  <en-drawer v-model="paymentDetail.visible" title="付款">
    <en-form :model="paymentDetail.data" :rules="paymentDetail.rules" :ref="setRef('paymentDetail')">
      <en-form-item :label="form.data.type?.code === 'PUC' ? '供应商：' : '付款对象'">{{ form.data.payee?.name }}</en-form-item>
      <en-form-item label="单据数量">{{ paymentDetail.summary.count }}</en-form-item>
      <en-form-item label="应付金额">{{ formatMoney(paymentDetail.summary.amount) }}</en-form-item>
      <en-form-item label="已付金额">{{ formatMoney(paymentDetail.summary.paidAmount) }}</en-form-item>
      <en-form-item label="未付金额">{{ formatMoney(paymentDetail.summary.unPayAmount) }}</en-form-item>
      <en-form-item label="付款方式" prop="paymentMethod">
        <select-maintain
          v-model="paymentDetail.data.paymentMethod"
          :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['PAMTMTD']) }"
          :props="{ label: 'name', value: 'name' }"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="金额">
        <en-input-number v-model="paymentDetail.data.amount" :min="0" class="w-full"></en-input-number>
      </en-form-item>
      <en-form-item label="优惠减免">
        <select-maintain
          v-model="paymentDetail.data.badDebt"
          :options="[
            { label: '否', value: 0 },
            { label: 'xx', value: calculator.sub(paymentDetail.summary.unPayAmount, paymentDetail.data.amount) }
          ]"
          :props="{ label: (option: any) => (option.label === 'xx' ? `优惠减免${option.value}` : option.label) }"
          :disabled="
            paymentDetail.summary.unPayAmount < 0 ||
            calculator.sub(paymentDetail.summary.unPayAmount, calculator.sub(paymentDetail.data.amount, paymentDetail.data.advanceAmount)) < 0
          "
          @change="paymentDetail.badDebt.change"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="冲减预付" class="flex">
        <en-input-number
          v-model="paymentDetail.data.advanceAmount"
          :max="paymentDetail.data.payee?.accountInfo?.paymentAdvance"
          :min="0"
          :disabled="paymentDetail.summary.unPayAmount <= 0 || !paymentDetail.data.payee?.accountInfo?.paymentAdvance"
          class="flex-1"
        ></en-input-number>
        <span>预付余额</span>
        <span>{{ formatMoney(paymentDetail.data.payee?.accountInfo?.paymentAdvance) }}</span>
      </en-form-item>
      <en-form-item label="减免审批人">
        <en-input v-model="paymentDetail.data.badDebtBy" :disabled="!paymentDetail.data.badDebt"></en-input>
      </en-form-item>
      <en-form-item label="付款时间" prop="businessDatetime">
        <en-date-picker
          v-model="paymentDetail.data.businessDatetime"
          type="datetime"
          value-format="YYYY-MM-DDThh:mm:ss"
          class="w-full"
        ></en-date-picker>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="paymentDetail.footer.cancel.click">取消</en-button>
      <button-ajax :method="paymentDetail.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>

  <en-dialog v-model="emstDetail.visible" :title="emstDetail.data.dialogTitle" width="90%">
    <ul class="d-flex list-box" v-if="form.data.type?.code === 'OTH'">
      <li class="item">
        <span>物流公司: </span>
        <span>{{ emstDetail.data.logisticsCompany && emstDetail.data.logisticsCompany.name }}</span>
      </li>
      <li class="item">
        <span>运费支付: </span>
        <span>{{ emstDetail.data.freightPaymentMethod && emstDetail.data.freightPaymentMethod.message }}</span>
      </li>
      <li class="item">
        <span>物流费用: </span>
        <span>{{ formatMoney(emstDetail.data.freight) }}</span>
      </li>
      <li class="item">
        <span>{{
          ['PUC', 'PUCR'].includes(emstDetail.data.documentTypeCode)
            ? emstDetail.data.documentTypeCode === 'PUC'
              ? '采购日期: '
              : '采退日期: '
            : '单据日期: '
        }}</span>
        <span>{{ formatDate(emstDetail.data.datetime) }}</span>
      </li>
      <li class="item">
        <template v-if="['PUC', 'PUCR'].includes(emstDetail.data.documentTypeCode)">
          <span>供应商: </span>
          <span>{{ emstDetail.data.supplier && emstDetail.data.supplier.name }}</span>
        </template>
        <template v-else>
          <span>客户名称: </span>
          <span>{{ emstDetail.data.client && emstDetail.data.client.name }}</span>
        </template>
      </li>
    </ul>

    <table-dynamic
      code="PUCDFLD"
      :data="emstDetail.data.purchaseGoods"
      :loading="emstDetail.loading"
      :method="emstDetail.purget"
      :config="emstDetail.config"
    >
      <template #SERIAL_NO="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.goods.serialNo }}
      </template>
      <template #OEM="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.goods.oem }}
      </template>
      <template #NAME="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.goods.name }}
      </template>
      <template #COUNT="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.count }}
      </template>
      <template #PRICE="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ formatMoney(row.price) }}
      </template>
      <template #PRICE_WITHOUT_TAX="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ formatMoney(row.preTaxPrice) }}
      </template>
      <template #AMOUNT="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ formatMoney(row.price * row.count) }}
      </template>
      <template #TAX_RATE="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ formatPercent(row.inventory.taxRate) }}
      </template>
      <template #BATCH_NO="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.inventory.batchNo }}
      </template>
      <template #AMOUNT_WITHOUT_TAX="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ formatMoney(row.preTaxAmount) }}
      </template>
      <template #MAINTENANCE_PRICE="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ formatMoney(row.servicePrice) }}
      </template>
      <template #WAREHOUSE_SHELF="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.warehouseShelf }}
      </template>
      <template #SHELF_LIFE="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.shelfLifeMonths }}
      </template>
      <template #GOODS_TYPE="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.goods.type.message }}
      </template>
      <template #VEHICLE_SPEC="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.goods.primaryVehicleSpec.join(',') }}
      </template>
      <template #BRAND="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.goods.brand }}
      </template>
      <template #PRODUCTION_PLACE="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.goods.placeOfProduction }}
      </template>
      <template #STOCKED_IN_COUNT="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.stockedCount }}
      </template>
      <template #LEFT_COUNT="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.count - row.stockedCount }}
      </template>
      <template #UNIT="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.goodsSpecification.name }}
      </template>
      <template #SPECIFICATION="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.goodsSpecification.weight }}
      </template>
      <template #COMMENT="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.comment }}
      </template>
    </table-dynamic>
    <table-dynamic
      v-if="emstDetail.data.documentTypeCode === 'PUCR'"
      code="PUCRDFLD"
      :data="emstDetail.data.purchaseGoods"
      :loading="emstDetail.loading"
      :method="emstDetail.purget"
      :config="emstDetail.config"
    >
      <template #SERIAL_NO="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.goods.serialNo }}
      </template>
      <template #OEM="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.goods.oem }}
      </template>
      <template #NAME="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.goods.name }}
      </template>
      <template #SPECIFICATION="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.goodsSpecification.name }}
      </template>
      <template #BATCH_NO="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.inventory.batchNo }}
      </template>

      <template #WAREHOUSE="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.warehouse.name }}
      </template>
      <template #WAREHOUSE_SHELF="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.warehouseShelf }}
      </template>
      <template #TAX_RATE="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ formatPercent(row.inventory.taxRate) }}
      </template>
      <template #PRICE="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ formatMoney(row.price) }}
      </template>
      <template #AMOUNT="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ formatMoney(row.price * row.count) }}
      </template>
      <template #MAINTENANCE_PRICE="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ formatMoney(row.servicePrice) }}
      </template>
      <template #PRICE_WITHOUT_TAX="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ formatMoney(row.preTaxPrice) }}
      </template>
      <template #BRAND="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.goods.brand }}
      </template>
      <template #VEHICLE="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.goods.vehicleSpecStr }}
      </template>
      <template #PRODUCTION_PLACE="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.goods.placeOfProduction }}
      </template>
      <template #COMMENT="{ row }: { row: EnocloudSettlementDefinitions['PayableDto'] }">
        {{ row.comment }}
      </template>
    </table-dynamic>
    <table-dynamic
      v-if="emstDetail.data.documentTypeCode === 'SAL'"
      code="SALODLFD"
      :data="emstDetail.data.saleGoods"
      :loading="emstDetail.loading"
      :method="emstDetail.salget"
      :config="emstDetail.config"
    >
      <template #INVENTORY="{ row }: { row: EnocloudInventoryDefinitions['SaleDto'] }">
        {{ formatNumber(row.batch ? calculator.div(row.batch.count, row.goodsSpecification.weight) : 0, 2) }}
      </template>
      <template #COUNT="{ row }: { row: EnocloudInventoryDefinitions['SaleDto'] }">
        {{ row.count }}
      </template>
      <template #GOODS_SERIAL_NO="{ row }: { row: EnocloudInventoryDefinitions['SaleDto'] }">
        {{ row.goodsSpecification.goods.serialNo }}
      </template>
      <template #PRICE="{ row }: { row: EnocloudInventoryDefinitions['SaleDto'] }">
        {{ formatMoney(row.price) }}
      </template>
      <template #AMOUNT="{ row }: { row: EnocloudInventoryDefinitions['SaleDto'] }">
        {{ formatMoney(calculator.mul(row.price, row.count)) }}
      </template>
      <template #AMOUNT_AFTER_DISCOUNT="{ row }: { row: EnocloudInventoryDefinitions['SaleDto'] }">
        {{ formatNumber(calculator.mul(row.price * row.count, row.discountRate)) }}
      </template>
      <template #DISCOUNT_RATE="{ row }: { row: EnocloudInventoryDefinitions['SaleDto'] }">
        {{ formatPercent(calculator.mul(row.discountRate, 100)) }}
      </template>
      <template #GOODS_SPECIFICATION_NAME="{ row }: { row: EnocloudInventoryDefinitions['SaleDto'] }">
        {{ row.goodsSpecification.name }}
      </template>
      <template #GOODS_VEHICLE_SPEC="{ row }: { row: EnocloudInventoryDefinitions['SaleDto'] }">
        {{ row.goods.primaryVehicleSpec.join(',') }}
      </template>
      <template #COMMENT="{ row }: { row: EnocloudInventoryDefinitions['SaleDto'] }">
        {{ row.comment }}
      </template>
    </table-dynamic>
    <table-dynamic
      v-if="emstDetail.data.documentTypeCode === 'SALR'"
      code="SALRLFD"
      :data="emstDetail.data.saleGoods"
      :loading="emstDetail.loading"
      :method="emstDetail.salget"
      :config="emstDetail.config"
    >
      <template #INVENTORY="{ row }: { row: EnocloudInventoryDefinitions['SaleDto'] }">
        {{ row.batch.count }}
      </template>
      <template #GOODS_SPECIFICATION_NAME="{ row }: { row: EnocloudInventoryDefinitions['SaleDto'] }">
        {{ row.goodsSpecification.name }}
      </template>
      <template #GOODS_SPECIFICATION_WEIGHT="{ row }: { row: EnocloudInventoryDefinitions['SaleDto'] }">
        {{ row.goodsSpecification.weight && row.goods.warehouseUnit }}
      </template>

      <template #COUNT="{ row }: { row: EnocloudInventoryDefinitions['SaleDto'] }">
        {{ formatNumber(row.count) }}
      </template>

      <template #PRICE="{ row }: { row: EnocloudInventoryDefinitions['SaleDto'] }">
        {{ formatMoney(row.price) }}
      </template>

      <template #AMOUNT="{ row }: { row: EnocloudInventoryDefinitions['SaleDto'] }">
        {{ formatMoney(calculator.mul(row.price, row.count)) }}
      </template>
      <template #COMMENT="{ row }: { row: EnocloudInventoryDefinitions['SaleDto'] }">
        {{ row.comment }}
      </template>
    </table-dynamic>
    <table-dynamic
      v-if="emstDetail.data.documentTypeCode === 'SERVC'"
      code="SVCOCMTFLD"
      :data="emstDetail.data.maintenances"
      :loading="emstDetail.loading"
      :method="emstDetail.serget"
      :config="emstDetail.config"
    >
      <template #ASSIGNEES="{ row }: { row: EnocloudServiceDefinitions['ServiceDto'] }">
        {{ row.assignees.map((item) => item.name).join(',') }}
      </template>
      <template #INFLATED_FLAG="{ row }: { row: EnocloudServiceDefinitions['ServiceDto'] }">
        {{ row.inflatedFlag && row.inflatedFlag.inflated ? 'X' : row.inflatedFlag.code === 'Z' ? 'Z' : '' }}
      </template>
    </table-dynamic>
  </en-dialog>
  <en-dialog v-model="addDetail.visible">
    <en-form :model="addDetail.data">
      <en-form-item label="采购单号">{{ addDetail.data.serialNo }}</en-form-item>
    </en-form>
  </en-dialog>
</template>

<script lang="ts">
import { h } from 'vue'
import dayjs from 'dayjs'
import { EnMessageBox } from '@enocloud/components'
import StatementOtherDetail from '@settlement/components/statement-other.vue'
import PayableDetail from '@settlement/components/payable-detail.vue'
import BatchDetails from '@settlement/components/batch-details.vue'
import PayDialog from '@settlement/components/pay-dialog.vue'
import { calculator } from '@enocloud/utils'

export const paymentDetailDataInit = (props?: Partial<EnocloudSettlementDefinitions['PaymentDto']>): EnocloudSettlementDefinitions['PaymentDto'] => {
  return Object.assign(
    {
      advanceAmount: 0,
      amount: 0,
      badDebt: 0,
      badDebtBy: '',
      businessDatetime: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
      paymentMethod: ''
    },
    props
  ) as any
}

export default factory({
  components: { PayDialog, StatementOtherDetail, PayableDetail, BatchDetails },

  config: {
    children: {
      operation: {
        open: {
          click(row: any) {
            this.emstDetail.visible = true
            this.emstDetail.data.id = row.documentId
            this.emstDetail.data.documentTypeCode = row.documentType.code
            switch (row.documentType.code) {
              case 'PUC':
                this.emstDetail.data.dialogTitle = '采购单详情'
                this.emstDetail.purget()
                break
              case 'PUCR':
                this.emstDetail.data.dialogTitle = '采退单详情'
                this.emstDetail.purget()
                break

              case 'SAL':
                this.emstDetail.data.dialogTitle = '销售单详情'
                this.emstDetail.salget()
                break
              case 'SALR':
                this.emstDetail.data.dialogTitle = '销退单详情'
                this.emstDetail.salget()
                break
              case 'SERVC':
                this.emstDetail.data.dialogTitle = '工单详情'
                this.emstDetail.serget()
                break
            }
          }
        },
        add: {
          click() {
            this.form.init()
            this.detail.visible = true
          },
          async command(option: string) {
            const unPayables = this.form.data.payables.filter((item) => !item.payableVerified?.value)
            const [unPayable] = unPayables

            switch (option) {
              case 'other':
                this.addDetail.visible = true
  
                break
              case 'logs':
                this.batchDetail.visible = true
                break
              case 'statement':
                try {
                  const res = await EnMessageBox({
                    title: '对账单详情',
                    message: h('div', { class: 'flex flex-col' }, [
                      h('span', (unPayable.type?.code === 'PUC' ? '供应商: ' : '付款对象: ') + (this.form.data?.payee?.name ?? '无')),
                      h('span', '单据数量: ' + unPayables.length),
                      h(
                        'span',
                        '对账金额: ' +
                          unPayables.reduce(
                            (amount, item) => calculator.add(amount, item.type?.code === 'PUC' ? item.purchase?.stocked.amount : item.amount),
                            0
                          )
                      )
                    ]),
                    showCancelButton: true
                  })

                  if (res === 'confirm') {
                    for (const item of unPayables) {
                      item.payableVerified = { code: 'Y', message: '', description: '', type: '', value: true }
                    }
                    await this.form.submit()
                    return this.form.get()
                  }
                } catch (err) {}
                break
              case 'del':
                this.form.delete().then(() => this.form.init())
            }
          }
        },
        submit: {
          async click() {
            await this.form.submit()
            return this.form.get()
          }
        },
        audit: {
          async click() {
            await this.form.audit()
            return this.form.get()
          }
        },
        cancel: {
          async click() {
            await this.form.cancel()
            return this.form.get()
          }
        },
        return: {
          async click() {
            await this.form.return()
            return this.form.get()
          }
        },
        delete: {
          async click() {
            await this.form.delete()
            return this.form.get()
          }
        },
        payment: {
          click() {
            this.paymentDetail.data = paymentDetailDataInit()
            this.paymentDetail.data.payee = this.form.data.payee!
            this.paymentDetail.data.amount = this.form.data.awaitingPaymentAmount
            this.paymentDetail.data.advanceAmount = 0
            this.paymentDetail.visible = true
          }
        }
      },
      manifest: {
        row: {
          click(row: EnocloudSettlementDefinitions['PayableDto']) {
            this.form.init()
            this.form.data.id = row.id
            this.form.get()
          }
        }
      },
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/settlement/payable/statement/:payableStatementId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'PUT /enocloud/settlement/payable/statement',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          refresh: {
            action: 'GET /enocloud/settlement/payable/statement/query',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          },

          cancel: {
            action: 'POST /enocloud/settlement/payable/statement/:payableStatementId/cancel',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          return: {
            action: 'POST /enocloud/settlement/payable/statement/:payableStatementId/cancel',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          audit: {
            action: 'POST /enocloud/settlement/payable/statement/:payableStatementId/audit',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          delete: {
            action: 'DELETE /enocloud/settlement/payable/statement/:payableStatementId',
            params(params) {
              params.paths = [this.form.data.id]
            }
          }
        },
        computed: {},
        children: {
          payables: {
            statement: {
              async click(row: EnocloudSettlementDefinitions['PayableDto']) {
                try {
                  const res = await EnMessageBox({
                    title: '对账单详情',
                    message: h('div', { class: 'flex flex-col gap-2' }, [
                      h('span', (row.type?.code === 'PUC' ? '供应商: ' : '付款对象: ') + (this.form.data.payee?.name ?? '无')),
                      h('span', '单据数量: ' + '1'),
                      h('span', { class: 'text-red' }, '对账金额: ¥' + (row.purchase?.stocked.amount ?? row.amount))
                    ]),
                    showCancelButton: true
                  })

                  if (res === 'confirm') {
                    row.payableVerified = { code: 'Y', message: '', description: '', type: '', value: true }
                    await this.form.submit()
                    return this.form.get()
                  }
                } catch (err) {}
              }
            },
            cancel: {
              async click(row: EnocloudSettlementDefinitions['PayableDto']) {
                row.payableVerified = { code: 'N', message: '', description: '', type: '', value: false }
                await this.form.submit()
                return this.form.get()
              }
            },
            delete: {
              click(index: number) {
                this.form.data.payables.splice(index, 1)
              }
            }
          }
        }
      },
      detail: {
        visible: true
      },
      dialog: {
        visible: false
      },
      payDialog: {
        visible: false
      },
      statementOtherDetail: {
        visible: false
      },
      batchDetail: {
        visible: false
      },
      paymentDetail: {
        visible: false,
        data: paymentDetailDataInit(),
        ajax: {
          submit: {
            action: 'POST /enocloud/settlement/payable/statement/:payableStatementId/payment',
            loading: true,
            validate: true,
            params(params) {
              params.paths = [this.form.data.id]
              params.payload = this.paymentDetail.data
            }
          }
        },
        rules: {
          paymentMethod: [{ required: true, message: '请选择付款方式' }],
          businessDatetime: [{ required: true, message: '请选择付款时间' }]
        },
        computed: {
          summary() {
            return {
              count: this.form.data.payables.length,
              amount: this.form.data.payableAmount,
              paidAmount: calculator.sub(this.form.data.payableAmount, this.form.data.awaitingPaymentAmount),
              unPayAmount: this.form.data.awaitingPaymentAmount
            }
          }
        },
        children: {
          badDebt: {
            change() {
              this.paymentDetail.data.badDebtBy = ''
             
            }
          },
          footer: {
            cancel: {
              click() {
                this.paymentDetail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.paymentDetail.submit()
                this.paymentDetail.visible = false
                return this.form.get()
              }
            }
          }
        }
      },
      emstDetail: {
        visible: false,
        data: {
          documentTypeCode: '',
          dialogTitle: '',
          id: '',
          purchaseGoods: [],
          saleGoods: [],
          logisticsCompany: {
            name: ''
          },
          freightPaymentMethod: {
            code: '',
            message: ''
          },
          freight: 0,
          datetime: '',
          client: { name: '' },
          supplier: { name: '' },
          maintenances: []
        },
        ajax: {
          purget: {
            action: 'GET /enocloud/purchase/:purchaseId',

            data: 'object',
            params(params) {
              params.paths = [this.emstDetail.data.id]
            },
            convert(data) {
              this.emstDetail.data.purchaseGoods = data.purchaseGoods?.map((item: any) => {
                item.warehouse = data.warehouse
                return item
              })
              return data
            }
          },
          salget: {
            action: 'GET /enocloud/sale/:saleId',
            data: 'object',

            params(params) {
              params.paths = [this.emstDetail.data.id]
            }
          },
          serget: {
            action: 'GET /enocloud/service/:serviceId',

            data: 'object',
            params(params) {
              params.paths = [this.emstDetail.data.id]
            },
            convert(data) {
              this.emstDetail.data.maintenances = data.maintenances.filter((item: any) => item.chargingMethod.code == 'SBT')
              return data
            }
          }
        }
      },
      addDetail: {
        visible: false,
        data: {},
        ajax: {
          purget: {
            action: 'GET /enocloud/settlement/payable',
            data: 'array',
            params(params) {
              params.payload = this.addDetail.data
            }
          }
        }
      }
    }
  },
  mounted() {
    this.form.get()
  }
})
</script>

<style scoped>
.list-box .item {
  margin-right: 20px;
  display: inline-block;
}
.list-box .item span:first-child {
  width: 80px;
  margin-right: 5px;
}
</style>
